import {Component, Inject} from '@angular/core';
// tslint:disable-next-line:import-blacklist
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    templateUrl: 'yes-no-popup.component.html',
    styleUrls: ['yes-no-popup.component.css'],
})

export class YesNoPopupComponent {

    public constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private _dialogRef: MatDialogRef<YesNoPopupComponent>) {
    }

    public close(ok: boolean): void {
        this._dialogRef.close(ok);
    }

    get data() {
        return this._data;
    }
}
