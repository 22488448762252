import {Observable} from 'rxjs/Observable';

export abstract class IExpressionEvaluationService {

    /**
     * Receives list of expressions as strings and returns evaluated values as
     * key, pair of expression and its value.
     * @param expressions - list of expressions to be evaluated
     */
    public abstract getEvaluatedExpressionsValues(expressions: string[]): Observable<Map<string, any>>;
}
