import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {ExportTemplatesService, HttpExportTemplatesService} from '@synisys/idm-export-templates-service-client-js';
import {ControlsModule} from '@synisys/idm-ng-controls';

import {MaterialModule} from '../../main/material.module';
import {MultilingualEditorComponent} from './multilingual-editor.component';
import {YesNoPopupComponent} from './yes-no-popup/yes-no-popup.component';
import {EditorModule} from '../editor/editor.module';

@NgModule({
    declarations: [
        MultilingualEditorComponent,
        YesNoPopupComponent
    ],
    imports: [
        FormsModule,
        CommonModule,
        ControlsModule,
        MaterialModule,
        ReactiveFormsModule,
        EditorModule
    ],
    entryComponents: [
        YesNoPopupComponent
    ],
    providers: [
        {provide: ExportTemplatesService, useClass: HttpExportTemplatesService}
    ],
    exports: [
        MultilingualEditorComponent
    ]
})
export class MultilingualEditorModule {
}
