import {Pipe, PipeTransform} from '@angular/core';
import {SelectedMetaFieldsModel} from '../model/selected-meta-fields.model';
import {MetaFieldType} from '@synisys/idm-kb-service-client-js';

@Pipe({
    name: 'fieldClassName',
    pure: true
})
export class FieldClassNamePipe implements PipeTransform {
    private isCategoryField(fieldType: number) {
        return fieldType === MetaFieldType.MAIN_ENTITY
            || fieldType === MetaFieldType.SUB_ENTITY
            || fieldType === MetaFieldType.MULTI_SELECT
            || fieldType === MetaFieldType.PARENT
            || fieldType === MetaFieldType.CLASSIFIER
            || fieldType === MetaFieldType.WORKFLOW_STATE
            || fieldType === MetaFieldType.USER;
        }

    transform(field: SelectedMetaFieldsModel): string {
        if (this.isCategoryField(field.metaFieldType)) {
            return 'mat-option-text__green';
        } else {
            return 'mat-option-text__blue';
        }
    }
}
