import {MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {ComboboxGroup, ComboboxOption, ComboboxOptions} from '@synisys/idm-ng-controls';
import {MetaFieldModel} from '../controls/editor/model/meta-field.model';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

const nonSimpleMetaFieldTypes: Array<MetaFieldType> = [
    MetaFieldType.SUB_ENTITY,
    MetaFieldType.PARENT
];

const identityMetaFieldTypes: Array<MetaFieldType> = [
    MetaFieldType.INTEGER_IDENTITY,
    MetaFieldType.INTEGER_INSTANCE
];

export function getComboBoxItems(metaFields: Array<MetaField>): Array<MetaFieldModel> {
    return metaFields.map((metaField: MetaField) => {
        const systemName = metaField.getSystemName();

        return new MetaFieldModel(
            metaField.getType() === MetaFieldType.CLASSIFIER ? `${systemName}.name` : systemName,
            systemName,
            metaField.getCompoundCategorySystemName(),
            metaField.getDisplayNameMultilingual() || metaField.getDisplayNameMsgId(),
            metaField.getType()
        );
    });
}

export function isSimpleField(metaField: MetaField): boolean {
    const metaFieldType: MetaFieldType = metaField.getType();
    return nonSimpleMetaFieldTypes.findIndex((type: MetaFieldType) => type === metaFieldType) < 0;
}

export function isIdentityField(metaField: MetaField): boolean {
    const metaFieldType: MetaFieldType = metaField.getType();
    return identityMetaFieldTypes.findIndex((type: MetaFieldType) => type === metaFieldType) < 0;
}

export function isFunctionalField(metaField: MetaField): boolean {
    const metaFieldType: MetaFieldType = metaField.getType();
    return metaFieldType === MetaFieldType.SUB_ENTITY;
}

export function createComboBoxOptionsGroup(metaFields: Array<MetaField>, subEntityName: string)
    : ComboboxGroup<ComboboxOptions<ComboboxOption<any, string | MultilingualString>>, string> {
    return new ComboboxGroup(
        metaFields.map((metaField: MetaField) => {
            const systemName = metaField.getSystemName();
            return new ComboboxOption<any, string | MultilingualString>({
                id: systemName,
                bindingName: metaField.getType() === MetaFieldType.CLASSIFIER ? systemName + '.name' : systemName
            }, metaField.getDisplayNameMultilingual() || metaField.getDisplayNameMsgId() || systemName);
        }), subEntityName);
}
