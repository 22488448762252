import {MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

export class MetaFieldModel {
    constructor(public bindingName: string, public name: string,
                public compoundName: string, public displayName: string | MultilingualString,
                public metaFieldType: MetaFieldType = null) {

    }
}
