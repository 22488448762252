import {Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output} from '@angular/core';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators';
import {LanguageService} from '@synisys/idm-message-language-service-client-js';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Template} from '@synisys/idm-export-templates-service-client-js';
import {MatDialog} from '@angular/material';
import {YesNoPopupComponent} from './yes-no-popup/yes-no-popup.component';
import {Observable} from 'rxjs/Observable';
import {KbService, MetaCategory, MetaCategoryId} from '@synisys/idm-kb-service-client-js';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';

@Component({
    selector: 'sis-multilingual-template-editor',
    templateUrl: './multilingual-editor.component.html',
    styleUrls: ['./multilingual-editor.component.css']
})

export class MultilingualEditorComponent implements OnInit, OnDestroy {

    /**
     * Indicates mode of editor, it can be simple text editor or export editor
     */
    @Input()
    public isSimpleEditorMode = false;

    /**
     * Indicates if editor is in the form and entity is present or is in export builder
     */
    @Input()
    public isEntityContextAvailable = false;

    @Input()
    public template: Template;

    @Output()
    public templateChange = new EventEmitter<Template>();

    public selectedLanguages: Array<Language> = [];

    public currentLanguage: Language;

    public metaCategoryId: MetaCategoryId;

    public nonInputLanguages: Array<Language> = [];

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    private _systemLanguages: Array<Language>;

    private _inputLanguages: Array<Language> = [];

    private _selectedNonInputLanguages: Array<Language> = [];

    get selectedNonInputLanguages(): Array<Language> {
        return this._selectedNonInputLanguages;
    }

    set selectedNonInputLanguages(value: Array<Language>) {
        this._selectedNonInputLanguages = value;
        this.selectedLanguages = this._systemLanguages.filter((language: Language) => {
            return this._selectedNonInputLanguages.find(l => l.getId() === language.getId())
                || this._inputLanguages.find(l => l.getId() === language.getId());
        });
        const map = new Map<number, string>();
        this.selectedLanguages.forEach((language: Language) => {
            const htmlTemplate = this.template.htmlTemplate.getValue(language.getId());
            if (htmlTemplate !== null && htmlTemplate !== undefined) {
                map.set(language.getId(), htmlTemplate);
            }
        });
        this.template.htmlTemplate = new MultilingualString(map);
    }

    constructor(private _languageService: LanguageService,
                private _dialog: MatDialog,
                private currentLanguageProvider: CurrentLanguageProvider,
                @Optional() private _kbService: KbService) {

        this.currentLanguageProvider.getCurrentLanguage().pipe(takeUntil(this._destroy$))
            .subscribe((language: Language) => this.currentLanguage = language);
    }

    public ngOnInit() {
        !this.isSimpleEditorMode && this.initMetaCategoryId();
        this.loadLanguages();
    }

    public ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    public setTemplate(templateValue: string, language: Language): void {
        this.template.htmlTemplate = new MultilingualString(this.template.htmlTemplate.toMap().set(language.getId(), templateValue));
        this.templateChange.emit(this.template);
    }

    public removeLanguage(language: Language): void {
        this._dialog.open(YesNoPopupComponent).beforeClose().pipe(takeUntil(this._destroy$)).subscribe(ok => {
            if (ok) {
                this.selectedLanguages = this.selectedLanguages.filter(l => l !== language);
                this._selectedNonInputLanguages = this._selectedNonInputLanguages.filter(l => l !== language);
                this.removeTemplate(language);
            }
        });
    }

    public removeTemplate(language: Language): void {
        const templateMap: Map<number, string> = this.template.htmlTemplate.toMap();
        templateMap.delete(language.getId());
        this.template.htmlTemplate = new MultilingualString(templateMap);
    }

    public isLanguageInput(language: Language): boolean {
        return this._inputLanguages.includes(language);
    }

    private loadLanguages(): void {
        Observable.zip(this._languageService.getAvailableLanguages(), this._languageService.getInputLanguages())
            .pipe(takeUntil(this._destroy$))
            .subscribe((data: Array<Array<Language>>) => {
                this._systemLanguages = data[0];
                this._inputLanguages = data[1];
                this._systemLanguages.forEach((language: Language) => {
                    const value = this.template.htmlTemplate.toMap().get(language.getId());
                    const valueExist = !!value && value.trim() !== '';
                    const isLanguageInput = this.isLanguageInput(language);
                    if (valueExist || isLanguageInput) {
                        this.selectedLanguages.push(language);
                    }
                    if (!isLanguageInput && valueExist) {
                        this._selectedNonInputLanguages.push(language);
                    }
                });
                this.nonInputLanguages = this._systemLanguages.filter(item => this._inputLanguages.indexOf(item) < 0);
            });
    }

    private initMetaCategoryId(): void {
        PreconditionCheck.notNullOrUndefined(this._kbService);

        this._kbService.getAllMetaCategories().pipe(takeUntil(this._destroy$))
            .subscribe((metaCategories: Set<MetaCategory>) => {
                metaCategories.forEach(value => {
                    if (value.getSystemName() === this.template.categoryName) {
                        this.metaCategoryId = value.getMetaCategoryId();
                    }
                });
            });
    }

}
