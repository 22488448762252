import {AfterViewInit, ChangeDetectorRef, Component, Inject, OnDestroy, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs/Subject';
import {MetaFieldModel} from '../model/meta-field.model';
import {takeUntil} from 'rxjs/operators';
import {KbService, MetaField, MetaFieldType} from '@synisys/idm-kb-service-client-js';
import {Observable} from 'rxjs/Observable';
import {getComboBoxItems, isSimpleField} from '../../../utils/meta-field-utils';
import {SelectedMetaFieldsModel} from '../model/selected-meta-fields.model';
import {SisSelectComponent} from '@synisys/idm-ng-controls';

@Component({
    selector: 'sis-template-editor-bind-field-popup',
    templateUrl: './editor-bind-field-popup.component.html',
    styleUrls: ['./editor-bind-field-popup.component.css']
})
export class EditorBindFieldPopupComponent implements OnDestroy {

    private _selectedMetaFieldName: string = null;

    public fields: Array<MetaFieldModel> = [new MetaFieldModel('', '', '', 'ui_tb.editor.add_dynamic_data.binding_label')];

    private _emptyState = '';

    private _simpleMetaFields: Array<MetaFieldModel> = [];

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    private _metaFieldErrorFlag: boolean;

    private _categoryName: string;

    public selectedMetaFields: SelectedMetaFieldsModel[] = [];

    private _selectedFieldsIndex = 0;

    private _selectedFields: string[] = [];

    public condition = '';

    public format = '';

    @ViewChild('metaFieldAdd') private _emptySelectMenu: SisSelectComponent;

    @ViewChildren('selected') private _selectMenusRef: QueryList<SisSelectComponent>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any,
                private kbService: KbService,
                private dialogRef: MatDialogRef<EditorBindFieldPopupComponent>,
                private cdr: ChangeDetectorRef) {
        this._categoryName = data.categoryName;
        this._emptyState = data.emptyState || '';
        this.condition = data.condition || '';
        this.format = data.format || '';
        this._selectedFields = data.selectedMetaFieldBinding ? data.selectedMetaFieldBinding.split('.').slice(1) : [];

        this.getMetaFields(data.categoryName)
            .pipe(takeUntil(this._destroy$)).subscribe(items => {

            if (this._selectedFields.length !== 0) {
                const item = items.find((field: Object) => field['name'] === this._selectedFields[this._selectedFieldsIndex]);
                this.selectedMetaFields.push(new SelectedMetaFieldsModel(item.name, items, item.metaFieldType));
                this.fields.push(item);

                this._selectedFieldsIndex++;
                if (this._selectedFieldsIndex < this._selectedFields.length) {
                    this.extractFields(item.compoundName);
                }
            } else {
                this._simpleMetaFields = items;
            }
        });
    }

    private extractFields(categoryName: string) {
        this.getMetaFields(categoryName)
            .pipe(takeUntil(this._destroy$)).subscribe(items => {
            const item = items.find((field: Object) => field['name'] === this._selectedFields[this._selectedFieldsIndex]);
            if (this._selectedFieldsIndex < this._selectedFields.length) {
                this.selectedMetaFields.push(new SelectedMetaFieldsModel(item.name, items, item.metaFieldType));
                this.fields.push(item);
            } else {
                this._simpleMetaFields = items;
                this._selectedMetaFieldName = null;
            }

            this._selectedFieldsIndex++;
            if (this._selectedFieldsIndex < this._selectedFields.length || item.compoundName) {
                this.extractFields(item.compoundName);
            }
        });
    }

    private getMetaFields(categoryName: string): Observable<Array<MetaFieldModel>> {
        return this.kbService.getMetaFields(categoryName)
            .map((metaFields: Array<MetaField>) => {
                return getComboBoxItems(metaFields.filter((metaField: MetaField) => isSimpleField(metaField)));
            });
    }

    public isDateField(field: SelectedMetaFieldsModel) {
        return field.metaFieldType === MetaFieldType.DATE || field.metaFieldType === MetaFieldType.DATE_TIME ||
            field.metaFieldType === MetaFieldType.LOCAL_DATE || field.metaFieldType === MetaFieldType.LOCAL_DATE_TIME;
    }

    public openMenu(sisSelect: SisSelectComponent) {
        sisSelect.open();
    }

    public onSelect(metaField: string, index: number): void {
        if (metaField) {
            if (index !== null) {
                this._simpleMetaFields = this.selectedMetaFields[index].metaFields;
                this.selectedMetaFields = this.selectedMetaFields.slice(0, index);
            }
            const selectedMetaField = this._simpleMetaFields
                .find((field: Object) => field['name'] === metaField);

            this.selectedMetaFields.push(new SelectedMetaFieldsModel(metaField, this.simpleMetaFields, selectedMetaField.metaFieldType));

            if (index != null && index < this.selectedMetaFields.length) {
                this.fields[index + 1] = selectedMetaField;
                this.fields = this.fields.slice(0, index + 2);
            } else {
                this.fields.push(selectedMetaField);
            }

            this._simpleMetaFields = [];

            if (selectedMetaField && selectedMetaField.compoundName) {
                this.getMetaFields(selectedMetaField.compoundName)
                    .pipe(takeUntil(this._destroy$)).subscribe(items => {
                    this._simpleMetaFields = items;
                });
            }
        }
    }

    public onClose(): void {
        this._emptySelectMenu.searchTerm = '';
        this._emptySelectMenu.reloadItems();
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save(): void {
        if (this.isValid()) {
            const selectedMetaFieldBinding = `${this._categoryName}.` + this.selectedMetaFields.map(item => item.selectedField).join('.');
            this.dialogRef.close(
                {
                    selectedMetaFieldBinding,
                    emptyState: this.emptyState,
                    condition: this.condition,
                    format: this.format,
                    isDateField: this.isDateField(this.selectedMetaFields[this.selectedMetaFields.length - 1])
                }
            );
        }
    }

    private isValid(): boolean {
        this._metaFieldErrorFlag = this.selectedMetaFields.length === 0;
        return !this._metaFieldErrorFlag;
    }

    private isCategoryField(fieldType: number) {
        return fieldType === MetaFieldType.MAIN_ENTITY
            || fieldType === MetaFieldType.SUB_ENTITY
            || fieldType === MetaFieldType.MULTI_SELECT
            || fieldType === MetaFieldType.PARENT
            || fieldType === MetaFieldType.CLASSIFIER
            || fieldType === MetaFieldType.WORKFLOW_STATE
            || fieldType === MetaFieldType.USER;
    }

    public ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    get selectedMetaFieldName(): string {
        return this._selectedMetaFieldName;
    }

    get isFormValid(): boolean {
        return this.selectedMetaFields.length !== 0 && !this.isCategoryField(this.fields[this.fields.length - 1].metaFieldType);
    }

    get emptyState(): string {
        return this._emptyState;
    }

    set emptyState(value: string) {
        this._emptyState = value;
    }

    get simpleMetaFields(): Array<MetaFieldModel> {
        return this._simpleMetaFields;
    }

    get metaFieldErrorFlag(): boolean {
        return this._metaFieldErrorFlag;
    }
}
