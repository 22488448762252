import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Optional, Output} from '@angular/core';
import {MatDialog} from '@angular/material';
import {Subject} from 'rxjs/Subject';
import {takeUntil} from 'rxjs/operators';

import {MessageService} from '@synisys/idm-message-language-service-client-js';

import {EditorBindFieldPopupComponent} from './editor-bind-field-popup/editor-bind-field-popup.component';
import {EditorBindTablePopupComponent} from './editor-bind-table-popup/editor-bind-table-popup.component';
import {TableModel} from './model/table.model';
import {MetaCategoryId} from '@synisys/idm-kb-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';

import {v4 as uuid} from 'uuid';
import {Language} from '@synisys/idm-crosscutting-concepts-frontend';
import {EditorExpressionPopupComponent} from './editor-expression-popup/editor-expression-popup.component';
import {editorStyles, qrCodeSrc} from './editor-static-data';
import {IExpressionEvaluationService} from '../../service/i-expression-evaluation.service';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {PreconditionCheck} from '@synisys/idm-common-util-frontend';
import * as moment from 'moment';


declare const $: any;

@Component({
    selector: 'sis-template-editor',
    templateUrl: './editor.component.html',
    styleUrls: ['./editor.component.css']
})
export class EditorComponent implements AfterViewInit, OnDestroy {

    /**
     * Indicates if editor is in the form and entity is present or is in export builder
     */
    @Input()
    public isEntityContextAvailable = false;

    /**
     * Indicates mode of editor, it can be simple text editor or export editor
     */
    @Input()
    public isSimpleEditorMode = false;

    @Input()
    public metaCategoryId: MetaCategoryId;

    @Input()
    public template = '';

    @Output()
    public templateChange: EventEmitter<string> = new EventEmitter<string>();

    public containerId: string;

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    private messages: Map<string, string>;

    private tooltipMessageKeys = [
        'ui_tb.editor.tools.bold_tooltip',
        'ui_tb.editor.tools.italic_tooltip',
        'ui_tb.editor.tools.underline_tooltip',
        'ui_tb.editor.tools.strikethrough_tooltip',
        'ui_tb.editor.tools.align_text_left_tooltip',
        'ui_tb.editor.tools.center_text_tooltip',
        'ui_tb.editor.tools.align_text_right_tooltip',
        'ui_tb.editor.tools.justify_tooltip',
        'ui_tb.editor.tools.insert_unordered_list_tooltip',
        'ui_tb.editor.tools.insert_ordered_list_tooltip',
        'ui_tb.editor.tools.indent_tooltip',
        'ui_tb.editor.tools.insert_hyperlink_tooltip',
        'ui_tb.editor.tools.create_table_tooltip',
        'ui_tb.editor.tools.view_html_tooltip',
        'ui_tb.editor.tools.format_tooltip',
        'ui_tb.editor.tools.clean_formatting_tooltip',
        'ui_tb.editor.tools.select_font_family_tooltip',
        'ui_tb.editor.tools.color_tooltip',
        'ui_tb.editor.tools.bg_color_tooltip',
        'ui_tb.editor.tools.select_font_size_tooltip',
        'ui_tb.editor.tools.add_dynamic_data_tooltip',
        'ui_tb.editor.tools.add_data_table_tooltip',
        'ui_tb.editor.add_expression.title',
        'ui_tb.editor.add_page_number.title',
        'ui_tb.editor.tools.add_image_table_tooltip',
        'ui_tb.editor.tools.table_wizard_tooltip',
        'ui_tb.editor.tools.add_row_above_tooltip',
        'ui_tb.editor.tools.add_row_below_tooltip',
        'ui_tb.editor.tools.add_column_left_tooltip',
        'ui_tb.editor.tools.add_column_right_tooltip',
        'ui_tb.editor.tools.delete_row_tooltip',
        'ui_tb.editor.tools.delete_column_tooltip',
        'ui_tb.editor.add_qr_code.title',
        'ui_tb.editor.dynamic_data_placeholder',
        'ui_tb.editor.total_page_number.title'
    ];

    private _uniqueId: string;

    private _editorId: string;

    private fontSize = 'inherit';

    private dynamicDataMessage = '';

    private dynamicFieldElements: any;

    private dynamicExpressionElements: any;

    private dynamicTableElements: any;

    constructor(private _dialog: MatDialog,
                private _messageService: MessageService,
                private _elementRef: ElementRef,
                private _currentLanguageProvider: CurrentLanguageProvider,
                @Optional() private expressionEvaluationService: IExpressionEvaluationService) {
        this._uniqueId = uuid();
        this.containerId = `sis-template-editor-${this._uniqueId}`;
        this._editorId = `sis-editor-${this._uniqueId}`;
    }

    public ngAfterViewInit() {
        this._currentLanguageProvider.getCurrentLanguage()
            .pipe(takeUntil(this._destroy$))
            .subscribe((language: Language) => this.updateEditor(language));
    }

    public ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    private updateEditor(language: Language) {
        // remove existing kendo editor component
        if (this.findElement(`#${this._editorId}`).data('kendoEditor')) {
            this.findElement(`#${this.containerId}`).empty();
        }

        // re-initialize editor
        this.initEditor(this.template, language);
    }

    private initEditor(value: string, language: Language) {
        //  load tooltip messages
        this._messageService.getMessages(language.getId(), this.tooltipMessageKeys)
            .then((messagesMap) => {
                this.messages = messagesMap;
                this.dynamicDataMessage = this.messages.get('ui_tb.editor.dynamic_data_placeholder');

                // create editor container
                const editorContainer = $('<textarea></textarea>');
                editorContainer.attr('id', this._editorId);
                editorContainer.attr('rows', '10');
                editorContainer.attr('cols', '30');
                editorContainer.attr('style', 'height:400px');
                editorContainer.appendTo(`#${this.containerId}`);

                // TODO: fix according bugs in future versions
                // enable shift on enter (without shift following element's styles are being copied)
                // const defaultTools = kendo.ui.Editor.defaultTools;
                // defaultTools['insertParagraph'].options.shift = true;

                this.findElement(`#${this._editorId}`).kendoEditor({
                    keyup: (e: any) => {
                        // Bind events after Ctrl+Z and Ctrl+Y
                        if ((e.keyCode === 89 || e.keyCode === 90) && e.ctrlKey && !this.isEntityContextAvailable) {
                            this.bindElementClickEvents();
                        }

                        if (e.keyCode === 46 || e.keyCode === 8) {
                            this.deleteEmptyDivs();
                        }
                    },
                    value: value,
                    change: (event: any) => this.templateChange.emit(event.sender.element.data('kendoEditor').value()),
                    tools: this.isEntityContextAvailable || this.isSimpleEditorMode ?
                        this.getStandardTools() : this.getStandardTools().concat(this.getCustomTools())
                });

                // append editor stylesheet
                const head = this.findElement('iframe').contents().find('head');
                const css = `<style type="text/css">${editorStyles}</style>`;
                $(head).append(css);

                this.findElement('iframe').height('600px');
                const editor = this.findElement(`#${this._editorId}`).data('kendoEditor');
                editor.bind('select', () => {
                    this.fontSize = editor.state('fontSize');
                });

                if (!this.isSimpleEditorMode) {
                    this.dynamicFieldElements = this.findContextElement('.label');
                    this.dynamicExpressionElements = this.findContextElement('.expression');
                    this.dynamicTableElements = this.findContextElement('.table');

                    this.fixTableStyles();

                    if (!this.isEntityContextAvailable) {
                        this.replaceContentWithPlaceholder();
                        this.bindCustomToolEvents();
                        this.bindElementClickEvents();
                        this.changeCursorType('pointer');
                    } else {
                        PreconditionCheck.notNullOrUndefined(this.expressionEvaluationService);

                        const expressions = this.collectDynamicBindings();
                        this.getEvaluatedExpressions(expressions)
                            .pipe(takeUntil(this._destroy$))
                            .subscribe((evaluatedExpressions) => {
                                evaluatedExpressions.size > 0 && this.replaceWithEvaluatedExpressions(evaluatedExpressions);
                            });

                        this.previewTable();
                        this.changeCursorType();
                    }
                    this.addTitleToExistingBindings();
                }
            });

    }

    private bindCustomToolEvents() {
        this.findElement('#bind-label-icon').click(() => this.openBindFieldPopup());
        this.findElement('#bind-table-icon').click(() => this.openBindTablePopup());
        this.findElement('#insert-image-icon').click(() => this.openUploadImagePopup());
        this.findElement('#expression-icon').click(() => this.openExpressionPopup());
        this.findElement('#total-page-number-tool-icon').click(() => this.createTotalPageNumberElement());
        this.findElement('#qr-code-tool-icon').click(() => this.createQRCodeElement());
    }

    private bindElementClickEvents() {
        this.dynamicFieldElements.unbind();
        this.dynamicTableElements.unbind();
        this.dynamicExpressionElements.unbind();
        this.dynamicFieldElements.click((event: any) => this.openBindFieldPopup($(event.delegateTarget)));
        this.dynamicExpressionElements.click((event: any) => this.openExpressionPopup($(event.delegateTarget)));
    }

    private changeCursorType(type = 'default') {
        this.dynamicFieldElements.css('cursor', `${type}`);
        this.dynamicExpressionElements.css('cursor', `${type}`);
    }

    /**
     * Adds title attribute to existing bindings for backward compatibility
     * @private
     */
    private addTitleToExistingBindings(): void {
        for (let i = 0; i < this.dynamicFieldElements.length; i++) {
            const element = this.dynamicFieldElements.eq(i);
            element.attr('title', element.attr('binding'));
        }
        for (let i = 0; i < this.dynamicExpressionElements.length; i++) {
            const element = this.dynamicExpressionElements.eq(i);
            element.attr('title', element.attr('binding'));
        }
    }

    /**
     * Replaces bindings content with static message for backward compatibility
     * @private
     */
    private replaceContentWithPlaceholder(): void {
        this.dynamicFieldElements.text(`[${this.dynamicDataMessage}]`);
        this.dynamicExpressionElements.text(`[${this.dynamicDataMessage}]`);
    }

    private openBindFieldPopup(field?: any): void {
        const data = {
            width: '650px',
            height: '500px',
            disableClose: true,
            data: {
                categoryName: this.metaCategoryId.getSystemName(),
                emptyState: field ? field.attr('emptystate') : '',
                selectedMetaFieldBinding: field ? field.attr('binding') : '',
                condition: field ? field.attr('condition') : '',
                format: field ? field.attr('format') : '',
            }
        };

        const dialogRef = this._dialog.open(EditorBindFieldPopupComponent, data);
        dialogRef.beforeClose().pipe(takeUntil(this._destroy$)).subscribe((model: any) => {
            if (model) {
                const element = this.insertOrUpdateElement(this.createBindFieldElement(model), field);
                element.click(() => this.openBindFieldPopup(element));
            }
        });
    }

    private openExpressionPopup(field?: any): void {
        const data = {
            width: '500px',
            height: '500px',
            disableClose: true,
            data: {
                expression: field ? field.attr('binding') : '',
                condition: field ? field.attr('condition') : '',
                emptyState: field ? field.attr('emptystate') : '',
            }
        };

        const dialogRef = this._dialog.open(EditorExpressionPopupComponent, data);
        dialogRef.beforeClose().pipe(takeUntil(this._destroy$)).subscribe((model: any) => {
            if (model) {
                const element = this.insertOrUpdateElement(this.createExpressionElement(model), field);
                element.click(() => this.openExpressionPopup(element));
            }
        });
    }

    private openBindTablePopup(table?: any): void {
        const data = {
            width: '500px',
            height: '500px',
            disableClose: true,
            data: {
                categoryName: this.metaCategoryId.getSystemName(),
                metaFieldSystemName: table ? table.attr('category').split('.')[1] : '',
                selectedColumns: table ? table.attr('selectedcolumns').split(',') : '',
                isNumbering: table ? table.attr('isNumbering') : '',
                isShowEmptyRow: table ? table.attr('isShowEmptyRow') : '',
                condition: table ? table.attr('condition') : '',
            }
        };

        const dialogRef = this._dialog.open(EditorBindTablePopupComponent, data);
        dialogRef.beforeClose().pipe(takeUntil(this._destroy$)).subscribe((model: TableModel) => {
            if (model) {
                const element = this.insertOrUpdateElement(this.createBindTableElement(model), table);
                element.click(() => this.openBindTablePopup(element));
            }
        });
    }

    private openUploadImagePopup(): void {
        const imageContainer = $('#selectImage');
        imageContainer.trigger('click');
        imageContainer.one('change', (event: any) => {
            const image = event.target.files[0];
            this.convertFileToBase64AndInsert(image);
        });

    }

    private async convertFileToBase64AndInsert(image: any) {
        const base64String = await new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(image);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });

        this.insertOrUpdateElement(this.createImageElement(base64String, image.imageName), null);
    }

    private insertOrUpdateElement(newElement: any, prevElement: any) {
        const newContent = newElement.prop('outerHTML');
        if (prevElement) {
            // replace the existing element in editor context.
            this.findContextElement(`#${prevElement.attr('id')}`).replaceWith(newContent);
        } else {
            // insert the new html element in the editor context.
            this.findElement(`#${this._editorId}`).data('kendoEditor').exec('inserthtml', {
                value: newContent
            });
        }
        // return the final html element for this binding.
        return this.findContextElement(`#${newElement.attr('id')}`);
    }

    private createBindFieldElement(data: any): Element {
        const selector = `bound-element-${new Date().getTime()}`;
        const boundSpanElement = $('<span></span>');
        if (data.isDateField) {
            boundSpanElement.attr('format', data.format);
        }
        boundSpanElement.attr('id', selector);
        boundSpanElement.attr('class', 'label');
        boundSpanElement.attr('binding', data.selectedMetaFieldBinding);
        boundSpanElement.attr('emptystate', data.emptyState);
        boundSpanElement.attr('contenteditable', 'false');
        boundSpanElement.attr('title', data.selectedMetaFieldBinding);

        if (this.fontSize !== 'inherit') {
            boundSpanElement.attr('style', `font-size: ${this.fontSize}`);
        }
        if (data.condition) {
            boundSpanElement.attr('condition', data.condition);
        }
        boundSpanElement.text(
            `[${this.dynamicDataMessage}]`
        );
        return boundSpanElement;
    }

    private createExpressionElement(data: any): Element {
        const selector = `bound-expression-${new Date().getTime()}`;
        const boundSpanElement = $('<span></span>');
        boundSpanElement.attr('id', selector);
        boundSpanElement.attr('class', 'expression');
        boundSpanElement.attr('binding', data.expression);
        boundSpanElement.attr('emptystate', data.emptyState);
        boundSpanElement.attr('contenteditable', 'false');
        boundSpanElement.attr('title', data.expression);

        if (this.fontSize !== 'inherit') {
            boundSpanElement.attr('style', `font-size: ${this.fontSize}`);
        }
        if (data.condition) {
            boundSpanElement.attr('condition', data.condition);
        }
        boundSpanElement.text(
            `[${this.dynamicDataMessage}]`
        );
        return boundSpanElement;
    }

    private createTotalPageNumberElement() {
        const selector = `bound-totalPageNumber-${new Date().getTime()}`;
        const boundSpanElement = $('<span></span>');
        boundSpanElement.attr('id', selector);
        boundSpanElement.attr('class', 'totalPageNumber');
        boundSpanElement.attr('contenteditable', 'false');
        boundSpanElement.attr('title', this.messages.get('ui_tb.editor.total_page_number.title'));

        if (this.fontSize !== 'inherit') {
            boundSpanElement.attr('style', `font-size: ${this.fontSize}`);
        }

        boundSpanElement.text(
            `[${this.dynamicDataMessage}]`
        );
        this.insertOrUpdateElement(boundSpanElement, undefined);
    }

    private createQRCodeElement() {
        const selector = `qr-code-image-element-${new Date().getTime()}`;

        const image = $('<img />', {
            id: selector,
            src: qrCodeSrc,
            alt: 'QR code',
            class: 'binding'
        });

        const element = $('<p>').append(image);

        this.insertOrUpdateElement(element, null);
    }

    private createImageElement(base64String: any, imageName: string): Element {
        const selector = `image-element-${new Date().getTime()}`;

        const image = $('<img />', {
            id: selector,
            src: base64String,
            alt: imageName,
            class: 'image'
        });

        return $('<p>').append(image);
    }

    private createBindTableElement(data: any) {
        const metaFieldSystemName = data.selectedFunctionalField;

        const tableElement = $('<div></div>');
        tableElement.attr('class', 'table');
        tableElement.attr('category', `${this.metaCategoryId.getSystemName()}.${metaFieldSystemName}`);
        tableElement.attr('id', `bound-table-${new Date().getTime()}`);
        tableElement.attr('selectedcolumns', data.selectedColumns.map((column: any) => column.value.id).toString());
        tableElement.attr('isNumbering', `${data.isNumbering}`);
        tableElement.attr('isShowEmptyRow', `${data.isShowEmptyRow}`);

        if (data.condition) {
            tableElement.attr('condition', data.condition);
        }

        const headerElement = this.getHeaderElement(data);
        const bodyElement = this.getBodyElement(data);

        if (!data.isShowEmptyRow) {
            bodyElement.css('display', 'none');
        } else {
            bodyElement.attr('style', 'display: flex; flex-wrap: nowrap');
        }

        tableElement.append(headerElement);
        tableElement.append(bodyElement);

        return tableElement;
    }

    private getHeaderElement(data: any) {
        const commonStyles = 'padding-left:4px;padding-bottom:14px;';
        const border = '1px solid grey';
        const headerElement = $('<header></header>');
        headerElement.attr('class', 'header');
        headerElement.attr('style', 'display: flex; flex-wrap: nowrap;');

        if (data.isNumbering) {
            const headerSpanElement = $('<span></span>');
            headerSpanElement.text('№');
            headerSpanElement.attr('style',
                `${commonStyles}border:${border};width:50px;`);
            headerElement.append(headerSpanElement);
        }

        data.selectedColumns.forEach((column: any, index: any) => {
                const headerSpanElement = $('<span></span>');
                headerSpanElement.text(column.name);

                if (index === 0 && !data.isNumbering) {
                    headerSpanElement.attr('style', `${commonStyles}border:${border};width:210px;`);
                } else {
                    headerSpanElement.attr('style',
                        `${commonStyles}border-top:${border};border-right:${border};border-bottom:${border};width:210px;`);
                }

                headerElement.append(headerSpanElement);
            }
        );
        return headerElement;
    }

    private getBodyElement(data: any) {
        const commonStyles = 'padding-left:4px;padding-bottom:14px;';
        const border = '1px solid grey';
        const bodyElement = $('<section></section>');
        bodyElement.attr('class', 'body');

        if (data.isNumbering) {
            const bodySpanElement = $('<span></span>');
            bodySpanElement.attr('style', `${commonStyles}border-left:${border};border-bottom:${border};border-right:${border};width:50px;`);
            bodySpanElement.attr('index', 'true');
            bodySpanElement.text('');
            bodyElement.append(bodySpanElement);
        }

        data.selectedColumns.forEach((column: any, index: any) => {

            const bodySpanElement = $('<span></span>');
            bodySpanElement.innerText = '';
            bodySpanElement.attr('binding', column.value.bindingName);

            if (index === 0 && !data.isNumbering) {
                bodySpanElement.attr('style', `${commonStyles}border-left:${border};border-bottom:${border};border-right:${border};width:210px;`);
            } else {
                bodySpanElement.attr('style', `${commonStyles}border-bottom:${border};border-right:${border};width:210px;`);
            }

            bodyElement.append(bodySpanElement);
        });
        return bodyElement;
    }

    private deleteEmptyDivs() {
        const divs = this.findContextElement('.resizable');

        divs.map((index: number, div: any) => {
            if (!div.getElementsByTagName('img').length) {
                div.remove();
            }
        });
    }

    private findElement(selector: string): any {
        return $(this._elementRef.nativeElement).find(selector);
    }

    private findContextElement(selector: string): any {
        return this.findElement('iframe').contents().find(selector);
    }

    private getStandardTools(): any[] {
        return [
            {name: 'bold', tooltip: this.messages.get('ui_tb.editor.tools.bold_tooltip')},
            {name: 'italic', tooltip: this.messages.get('ui_tb.editor.tools.italic_tooltip')},
            {name: 'underline', tooltip: this.messages.get('ui_tb.editor.tools.underline_tooltip')},
            {name: 'strikethrough', tooltip: this.messages.get('ui_tb.editor.tools.strikethrough_tooltip')},
            {name: 'justifyLeft', tooltip: this.messages.get('ui_tb.editor.tools.align_text_left_tooltip')},
            {name: 'justifyCenter', tooltip: this.messages.get('ui_tb.editor.tools.center_text_tooltip')},
            {name: 'justifyRight', tooltip: this.messages.get('ui_tb.editor.tools.align_text_right_tooltip')},
            {name: 'justifyFull', tooltip: this.messages.get('ui_tb.editor.tools.justify_tooltip')},
            {
                name: 'insertUnorderedList',
                tooltip: this.messages.get('ui_tb.editor.tools.insert_unordered_list_tooltip')
            },
            {name: 'insertOrderedList', tooltip: this.messages.get('ui_tb.editor.tools.insert_ordered_list_tooltip')},
            {name: 'indent', tooltip: this.messages.get('ui_tb.editor.tools.indent_tooltip')},
            {name: 'outdent', tooltip: this.messages.get('ui_tb.editor.tools.outdent_tooltip')},
            {name: 'createLink', tooltip: this.messages.get('ui_tb.editor.tools.insert_hyperlink_tooltip')},
            {name: 'unlink', tooltip: this.messages.get('ui_tb.editor.tools.unlink_tooltip')},
            {name: 'tableWizard', tooltip: this.messages.get('ui_tb.editor.tools.table_wizard_tooltip')},
            {name: 'createTable', tooltip: this.messages.get('ui_tb.editor.tools.create_table_tooltip')},
            {name: 'addRowAbove', tooltip: this.messages.get('ui_tb.editor.tools.add_row_above_tooltip')},
            {name: 'addRowBelow', tooltip: this.messages.get('ui_tb.editor.tools.add_row_below_tooltip')},
            {name: 'addColumnLeft', tooltip: this.messages.get('ui_tb.editor.tools.add_column_left_tooltip')},
            {name: 'addColumnRight', tooltip: this.messages.get('ui_tb.editor.tools.add_column_right_tooltip')},
            {name: 'deleteRow', tooltip: this.messages.get('ui_tb.editor.tools.delete_row_tooltip')},
            {name: 'deleteColumn', tooltip: this.messages.get('ui_tb.editor.tools.delete_column_tooltip')},
            {name: 'viewHtml', tooltip: this.messages.get('ui_tb.editor.tools.view_html_tooltip')},
            {name: 'formatting', tooltip: this.messages.get('ui_tb.editor.tools.format_tooltip')},
            {name: 'cleanFormatting', tooltip: this.messages.get('ui_tb.editor.tools.clean_formatting_tooltip')},
            {
                name: 'fontName',
                items: [
                    {text: 'Arial', value: 'Arial'},
                    {text: 'Ariblk', value: 'Ariblk'},
                    {text: 'Bangla', value: 'Bangla'},
                    {text: 'Ecsquaresanspro', value: 'Ecsquaresanspro'},
                    {text: 'Kalpurush', value: 'Kalpurush'},
                    {text: 'Lucon', value: 'Lucon'},
                    {text: 'Nikosh', value: 'Nikosh'},
                    {text: 'Times', value: 'Times'},
                    {text: 'Verdana', value: 'Verdana'}
                ], tooltip: this.messages.get('ui_tb.editor.tools.select_font_family_tooltip')
            },
            {name: 'foreColor', tooltip: this.messages.get('ui_tb.editor.tools.color_tooltip')},
            {name: 'backColor', tooltip: this.messages.get('ui_tb.editor.tools.bg_color_tooltip')},
            {
                name: 'fontSize',
                items: [
                    {text: '11px', value: '11px'},
                    {text: '12px', value: '12px'},
                    {text: '13px', value: '13px'},
                    {text: '14px', value: '14px'},
                    {text: '15px', value: '15px'},
                    {text: '16px', value: '16px'},
                    {text: '18px', value: '18px'},
                    {text: '20px', value: '20px'}
                ], tooltip: this.messages.get('ui_tb.editor.tools.select_font_size_tooltip')
            }
        ];
    }

    private getCustomTools(): any[] {
        return [
            {
                name: 'customToolFieldBinding',
                template: `
                        <div type="text/x-kendo-template" id="label-binding" style="display: flex; align-items: center; cursor: pointer;">
                           <img
                               id="bind-label-icon"
                               src="icons/label_ic.svg"
                               title="${this.messages.get('ui_tb.editor.tools.add_dynamic_data_tooltip')}"
                               alt="Bind field icon"
                           />
                        </div>`
            },
            {
                name: 'customToolTableBinding',
                template:
                    `<div type="text/x-kendo-template" id="table-binding"
                                style="display: flex; align-items: center; cursor: pointer;">
                            <img
                                id="bind-table-icon"
                                src="icons/table_ic.svg"
                                title="${this.messages.get('ui_tb.editor.tools.add_data_table_tooltip')}"
                                alt="Bind table icon"
                            />
                        </div>`
            },
            {
                name: 'customToolExpression',
                template:
                    `<div type="text/x-kendo-template" id="expression-tool"
                                style="display: flex; align-items: center; cursor: pointer;">
                            <img
                                id="expression-icon"
                                src="icons/expression.svg"
                                title="${this.messages.get('ui_tb.editor.add_expression.title')}"
                                alt="Bind table icon"
                            />
                        </div>`
            },
            {
                name: 'customToolTotalPageNumber',
                template:
                    `<div type="text/x-kendo-template" id="total-page-number-tool"
                                style="display: flex; align-items: center; cursor: pointer;">
                            <img
                                id="total-page-number-tool-icon"
                                src="icons/totalPageNumber.svg"
                                title="${this.messages.get('ui_tb.editor.add_page_number.title')}"
                                alt="Page Number icon"
                            />
                        </div>`
            },
            {
                name: 'customToolInsertImage',
                template:
                    `
                        <input style="display:none" type="file" id="selectImage"
                                               accept=".png, .jpg, .jpeg, .gif, .svg, .bmp, .exif, .jfif, .jif, .tiff, .tif">
                        <div
                            type="text/x-kendo-template"
                            class="k-icon k-i-image"
                            id="insert-image-icon"
                            title="${this.messages.get('ui_tb.editor.tools.add_image_table_tooltip')}"
                        ></div>`
            },
            {
                name: 'qrCodeTool',
                template:
                    `<div type="text/x-kendo-template" id="qr-code-tool"
                                style="display: flex; align-items: center; cursor: pointer;">
                            <img
                                id="qr-code-tool-icon"
                                src="icons/qr_ic.svg"
                                title="${this.messages.get('ui_tb.editor.add_qr_code.title')}"
                                alt="QR code"
                            />
                        </div>`
            }
        ];
    }

    /**
     * Collects all dynamic bindings, conditions, expressions.
     * @return string[] - list of bindings
     * @private
     */
    private collectDynamicBindings(): string[] {
        const expressions: string[] = [];
        this.dynamicFieldElements.each((index: number, value: any) => {
            const binding = $(value).attr('binding');
            const condition = $(value).attr('condition');

            binding && binding.length > 0 && expressions.push('${' + binding + '}');
            condition && condition.length > 0 && expressions.push('${' + condition + '}');
        });
        this.dynamicExpressionElements.each((index: number, value: any) => {
            const binding = $(value).attr('binding');
            const condition = $(value).attr('condition');

            binding && binding.length > 0 && expressions.push('${' + binding + '}');
            condition && condition.length > 0 && expressions.push('${' + condition + '}');
        });

        return expressions;
    }

    private getEvaluatedExpressions(expressions: string[]): Observable<Map<string, any>> {
        return expressions.length > 0 ?
            this.expressionEvaluationService.getEvaluatedExpressionsValues(expressions)
            : of(new Map());
    }

    /**
     * Replaces dynamic bindings with evaluated values
     * @param evaluatedExpressions
     * @private
     */
    private replaceWithEvaluatedExpressions(evaluatedExpressions: Map<string, any>): void {
        this.dynamicFieldElements.each((index: number, element: any) => {
            $(element).text(this.calculateExpressionDisplayValue(element, evaluatedExpressions));
        });
        this.dynamicExpressionElements.each((index: number, element: any) => {
            $(element).text(this.calculateExpressionDisplayValue(element, evaluatedExpressions));
        });
    }

    /**
     * Calculates dynamic bindings conditions, format and empty state and returns final value
     * @param element
     * @param evaluatedExpressions
     * @return string - final value
     * @private
     */
    private calculateExpressionDisplayValue(element: any, evaluatedExpressions: Map<string, any>): string {
        element = $(element);
        const binding = element.attr('binding');
        const condition = element.attr('condition');
        const emptyState = element.attr('emptyState') || '';
        const format = element.attr('format');

        let evaluatedBinding = binding ? evaluatedExpressions.get(binding) : '';
        format !== undefined && evaluatedBinding && (evaluatedBinding = moment(new Date(evaluatedBinding))['formatWithJDF'](format));
        const evaluatedCondition = condition ? evaluatedExpressions.get(condition) : true;

        return evaluatedCondition ? evaluatedBinding || emptyState : '';
    }

    // TODO: should be removed in future
    private previewTable() {
        this.findContextElement('.body').attr('style', 'display: flex; flex-wrap: nowrap; height: 30px');
    }

    // TODO: should be removed in future
    private fixTableStyles() {
        this.dynamicTableElements.each((index: number, value: any) => {
            $(value).css('cursor', 'default');
            if (value) {
                const commonStyles = {
                    'display': '',
                    'padding': '',
                    'padding-left': '4px',
                    'padding-bottom': '14px',
                };
                const border = '1px solid grey';

                const headerElement = $(value.children[0]);
                const bodyElement = $(value.children[1]);

                headerElement.attr('style', 'display: flex; flex-wrap: nowrap;');

                const headerColumns = headerElement.find('span');
                headerColumns.each((columnIndex: number, columnValue: any) => {
                    $(columnValue).css(commonStyles);
                    $(columnValue).css({
                        'border-top': '',
                        'border-right': '',
                        'border-bottom': '',
                        'border-left': ''
                    });
                    if (columnIndex === 0) {
                        $(columnValue).css({'border': `${border}`});
                    } else {
                        $(columnValue).css({
                            'border-top': `${border}`,
                            'border-right': `${border}`,
                            'border-bottom': `${border}`
                        });
                    }
                });

                const bodyColumns = bodyElement.find('span');
                bodyColumns.each((columnIndex: number, columnValue: any) => {
                    $(columnValue).css(commonStyles);
                    $(columnValue).css({
                        'border-top': '',
                        'border-right': '',
                        'border-bottom': '',
                        'border-left': ''
                    });
                    if (columnIndex === 0) {
                        $(columnValue).css({
                            'border-left': `${border}`,
                            'border-right': `${border}`,
                            'border-bottom': `${border}`
                        });
                    } else {
                        $(columnValue).css({
                            'border-right': `${border}`,
                            'border-bottom': `${border}`
                        });
                    }
                });

            }
        });
    }
}
