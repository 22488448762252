// tslint:disable-next-line:max-line-length
export const qrCodeSrc = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHQAAAB0CAYAAABUmhYnAAAAAklEQVR4AewaftIAAAJ6SURBVO3BQY6kAAwEwSyL/385t48+ISGgZ8briPjBGqNYoxRrlGKNUqxRijVKsUYp1ijFGqVYoxRrlGKNUqxRijVKsUYp1igHNyXhm1TOJKFTuSMJ36RyR7FGKdYoxRrl4GEqT0rCk5LQqVyh8qQkPKlYoxRrlGKNcvCyJFyhckUSOpUzKl0SOpUrknCFypuKNUqxRinWKAf/mSRMVqxRijVKsUY5GC4JnUqXhEmKNUqxRinWKAcvU/lJKm9S+U2KNUqxRinWKAcPS8JvkoRO5Y4k/GbFGqVYoxRrlIObVP6SJFyh8pcUa5RijVKsUQ5uSkKn0iXhSSqdypuS8CSVNxVrlGKNUqxR4gcPSsIVKnckoVP5SUnoVM4koVO5o1ijFGuUYo0SP3hREjqVLglXqNyRhCtUnpSETuVJxRqlWKMUa5SDL0tCp/JNKl0SrkhCp9Il4ScVa5RijVKsUQ5uSsIZlS4JXRImU+mS8KZijVKsUYo1SvzgD0tCp/KmJNyh8qZijVKsUYo1SvzghiR8k8odSTij0iXhjMqZJJxReVKxRinWKMUa5eBhKk9Kwpkk3KFyRuWKJJxR6ZLQqdxRrFGKNUqxRjl4WRKuUHmSSpeEK5JwhcqZJLypWKMUa5RijXIwjMoZlStUziThCpU3FWuUYo1SrFEO/jNJOKNyhUqXhDMqbyrWKMUapVijHLxM5U0qXRI6lTMqXRI6lS4JncpvUqxRijVKsUY5eFgSvikJnUqXhE6lS0Kn0iWhU7lCpUtCp/KkYo1SrFGKNUr8YI1RrFGKNUqxRinWKMUapVijFGuUYo1SrFGKNUqxRinWKMUapVijFGuUfwQV5NsdPKgiAAAAAElFTkSuQmCC';

export const editorStyles = `
         .table {
          display: inline-block;
          cursor: pointer;
          user-select: none; }

        .label, .expression, .totalPageNumber {
          cursor: pointer;
          opacity: 0.8;
          background-color: #fef586;
          }
        .resizable {
          width: 10%;
          height: auto;
          resize: horizontal;
          overflow: auto; }
    `;

