import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {takeUntil, map, switchMap} from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/observable/of';
import {Subject} from 'rxjs/Subject';

import {ComboboxGroup, ComboboxOption, ComboboxOptions} from '@synisys/idm-ng-controls';
import {KbService, MetaField} from '@synisys/idm-kb-service-client-js';

import {getComboBoxItems, createComboBoxOptionsGroup, isFunctionalField, isSimpleField} from '../../../utils/meta-field-utils';
import {TableModel} from '../model/table.model';
import {MetaFieldModel} from '../model/meta-field.model';
import {TranslatorPipe} from '../../../pipe/translator.pipe';

declare const $: any;

@Component({
    selector: 'sis-template-editor-bind-table-popup',
    templateUrl: './editor-bind-table-popup.component.html',
    styleUrls: ['./editor-bind-table-popup.component.css'],
    providers: [TranslatorPipe]
})
export class EditorBindTablePopupComponent implements OnDestroy {

    private _categoryName: string;

    private _isNumbering: boolean;

    private _isShowEmptyRow: boolean;

    private _validations = new Map<string, string>();

    private _showValidations = false;

    private _functionalMetaFields: Array<MetaFieldModel>;

    private _selectedFunctionalField: string;

    private _columnsData: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOption<any, string>>, string>> = [];

    private _selectedColumns: Array<string> = [];

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    private _onFieldIdChange$: Subject<string> = new Subject<string>();

    private _functionalFieldErrorFlag: boolean;

    private _selectedColumnsErrorFlag: boolean;

    public condition = '';

    constructor(@Inject(MAT_DIALOG_DATA) private _data: any, private _kbService: KbService,
                private _dialogRef: MatDialogRef<EditorBindTablePopupComponent>, private _messagePipe: TranslatorPipe) {

        this._onFieldIdChange$
            .pipe(
                map(fieldId => this.getFieldCompoundSystemName(fieldId)),
                switchMap(categoryName => this.getColumns(categoryName)),
                takeUntil(this._destroy$)
            )
            .subscribe(columns => {
                this._selectedColumns = [];
                this._columnsData = columns;
            });

        this.getFunctionalFields(_data.categoryName)
            .pipe(takeUntil(this._destroy$))
            .subscribe(items => {
                this._functionalMetaFields = items;
                this._selectedFunctionalField = _data.metaFieldSystemName;
                this._onFieldIdChange$.next(this._selectedFunctionalField);
            });

        this._categoryName = _data.categoryName;
        this._selectedColumns = _data.selectedColumns || [];
        this._isNumbering = (_data.isNumbering === 'true');
        this._isShowEmptyRow = (_data.isShowEmptyRow === 'true');
        this.condition = _data.condition || '';
    }

    public save() {
        if (this.isValid()) {
            const selectedOptions = this._selectedColumns
                .map(columnId => this._columnsData[0].items
                    .find(option => option.value.id === columnId)
                );
            this._dialogRef.close(
                new TableModel(selectedOptions, this._selectedFunctionalField, this._isNumbering, this._isShowEmptyRow, this.condition)
            );
        }
    }

    public close(): void {
        this._dialogRef.close();
    }

    public onSelect(field: string): void {
        this._selectedFunctionalField = field;
        this._onFieldIdChange$.next(this._selectedFunctionalField);
    }

    private getFunctionalFields(categoryName: string): Observable<Array<MetaFieldModel>> {
        return this._kbService.getMetaFields(categoryName)
            .map((metaFields: Array<MetaField>) => {
                return getComboBoxItems(metaFields.filter((metaField: MetaField) => isFunctionalField(metaField)));
            });
    }

    private getColumns(subEntityName: string)
        : Observable<ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOption<any, string>>, string>>> {
        return subEntityName ? this._kbService.getMetaFields(subEntityName)
            .map((columns: Array<MetaField>) => {
                return [createComboBoxOptionsGroup(columns.filter((metaField: MetaField) => isSimpleField(metaField)), subEntityName)];
            }).flatMap((options: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOption<any, string>>, string>>) => {
                return Observable.zip(...options[0].items
                    .map((option: ComboboxOption<any, string>) => {
                        return this.translateColumnName(option);
                    }));
            }).map((optionItems: ComboboxOptions<ComboboxOption<any, string>>) => {
                return [new ComboboxGroup(optionItems, subEntityName)];
            }) : of([])
            ;
    }

    private translateColumnName(option: ComboboxOption<any, string>): Observable<ComboboxOption<any, string>> {
        return this._messagePipe.transform(option.name).map((name: string) => {
            return Object.assign({}, option, {name: name || ''});
        });
    }

    private getFieldCompoundSystemName(systemName: string) {
        return systemName ? this._functionalMetaFields
            .find((metaFieldView: Object) => metaFieldView['name'] === systemName)['compoundName'] : null;
    }

    public changeColumnsOrder(items: Array<string>) {
        this._selectedColumns = items;
    }

    public setValues(values: Array<string>) {
        this._selectedColumns = values;
    }

    private isValid(): boolean {
        this._functionalFieldErrorFlag = this._selectedFunctionalField == null || this._selectedFunctionalField === '';
        this._selectedColumnsErrorFlag = !this._selectedColumns || this._selectedColumns.length === 0;

        return !(this._functionalFieldErrorFlag || this._selectedColumnsErrorFlag);
    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    get validations(): Map<string, string> {
        return this._validations;
    }

    get showValidations(): boolean {
        return this._showValidations;
    }

    get selectedFunctionalField(): string {
        return this._selectedFunctionalField;
    }

    get isNumbering(): boolean {
        return this._isNumbering;
    }

    get functionalMetaFields(): Array<MetaFieldModel> {
        return this._functionalMetaFields;
    }

    get selectedColumns(): Array<string> {
        return this._selectedColumns;
    }

    get columnsData(): ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOption<any, string>>, string>> {
        return this._columnsData;
    }

    set isNumbering(value: boolean) {
        this._isNumbering = value;
    }

    get isShowEmptyRow(): boolean {
        return this._isShowEmptyRow;
    }

    set isShowEmptyRow(value: boolean) {
        this._isShowEmptyRow = value;
    }

    set validations(value: Map<string, string>) {
        this._validations = value;
    }

    set functionalMetaFields(value: Array<MetaFieldModel>) {
        this._functionalMetaFields = value;
    }

    set selectedFunctionalField(value: string) {
        this._selectedFunctionalField = value;
    }

    set columnsData(value: ComboboxOptions<ComboboxGroup<ComboboxOptions<ComboboxOption<any, string>>, string>>) {
        this._columnsData = value;
    }

    set selectedColumns(value: Array<string>) {
        this._selectedColumns = value;
    }

    get functionalFieldErrorFlag(): boolean {
        return this._functionalFieldErrorFlag;
    }

    get selectedColumnsErrorFlag(): boolean {
        return this._selectedColumnsErrorFlag;
    }
}
