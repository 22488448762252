import {ComboboxOption, ComboboxOptions} from '@synisys/idm-ng-controls';

export class TableModel {

    private _selectedColumns: ComboboxOptions<ComboboxOption<any, string>>;
    private _selectedFunctionalField: string;
    private _isNumbering: boolean;
    private _isShowEmptyRow: boolean;
    private _condition: string;

    constructor(selectedColumns: ComboboxOptions<ComboboxOption<any, string>>,
                selectedFunctionalField: string, isNumbering: boolean, isShowEmptyRow: boolean, condition?: string) {
        this._selectedColumns = selectedColumns;
        this._selectedFunctionalField = selectedFunctionalField;
        this._isNumbering = isNumbering;
        this._isShowEmptyRow = isShowEmptyRow;
        this._condition = condition;
    }

    get selectedColumns(): ComboboxOptions<ComboboxOption<any, string>> {
        return this._selectedColumns;
    }

    set selectedColumns(value: ComboboxOptions<ComboboxOption<any, string>>) {
        this._selectedColumns = value;
    }

    get selectedFunctionalField(): string {
        return this._selectedFunctionalField;
    }

    set selectedFunctionalField(value: string) {
        this._selectedFunctionalField = value;
    }

    get isNumbering(): boolean {
        return this._isNumbering;
    }

    set isNumbering(value: boolean) {
        this._isNumbering = value;
    }

    get isShowEmptyRow(): boolean {
        return this._isShowEmptyRow;
    }

    set isShowEmptyRow(value: boolean) {
        this._isShowEmptyRow = value;
    }

    get condition(): string {
        return this._condition;
    }

    set condition(value: string) {
        this._condition = value;
    }
}
