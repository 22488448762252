import {Component, Inject, OnDestroy} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {Subject} from 'rxjs/Subject';
import {KbService} from '@synisys/idm-kb-service-client-js';

@Component({
    selector: 'sis-template-editor-expression-popup',
    templateUrl: './editor-expression-popup.component.html',
    styleUrls: ['./editor-expression-popup.component.css']
})
export class EditorExpressionPopupComponent implements OnDestroy {

    public expressionErrorFlag: boolean;

    public expression = '';

    public condition = '';

    public emptyState = '';

    private _destroy$: Subject<boolean> = new Subject<boolean>();

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private kbService: KbService,
                private dialogRef: MatDialogRef<EditorExpressionPopupComponent>) {

        this.expression = data.expression || '';
        this.condition = data.condition || '';
        this.emptyState = data.emptyState || '';
    }

    public close(): void {
        this.dialogRef.close();
    }

    public save(): void {
        if (this.isValid()) {
            this.dialogRef.close(
                {
                    expression: this.expression,
                    condition: this.condition,
                    emptyState: this.emptyState,
                }
            );
        }
    }

    public ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    }

    private isValid(): boolean {
        this.expressionErrorFlag = this.expression.length === 0;
        return !this.expressionErrorFlag;
    }

}
