import {Pipe, PipeTransform} from '@angular/core';
import {Observable} from 'rxjs/Observable';

import {MessageService} from '@synisys/idm-message-language-service-client-js';
import {CurrentLanguageProvider} from '@synisys/idm-session-data-provider-api-js';
import {Language, MultilingualString} from '@synisys/idm-crosscutting-concepts-frontend';

/**
 * Transforming message key to message using current language.
 */
@Pipe({
    name: 'translator',
    pure: true
})
export class TranslatorPipe implements PipeTransform {

    public constructor(private messageService: MessageService, private currentLanguageProvider: CurrentLanguageProvider) {
    }

    transform(inputString: string | MultilingualString, alias: string = ''): Observable<string> {
        if (!inputString) {
            return Observable.of(alias);
        }

        if (inputString instanceof MultilingualString) {
            const multilingualString = inputString.toMap();
            return this.currentLanguageProvider.getCurrentLanguage().map((language: Language) => {
                return multilingualString.get(language.getId()) || alias;
            });
        }

        if (typeof inputString === 'string') {
            const key = inputString ? inputString.trim() : '';

            if (key.length === 0) {
                return Observable.from([alias]);
            }

            return this.messageService.getMessage(key).map(translation => translation || key);
        }
    }
}
